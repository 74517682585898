import React from "react"
import CarraraLogoType from "../images/carrara_logo/carrara-logotype.svg"
import { useStaticQuery, graphql, Link } from "gatsby"
import {
  FaClock
} from "react-icons/fa"
export default () => {
  return (
    <section
      className="bg-half-170 lp-image"
      style={{ background: 'url(https://res.cloudinary.com/carrara-pilates/image/upload/c_scale,q_80,w_2070/v1585161290/gallery/landing-summary/gallery_carrara_149_uon13z.jpg) center center' }}
      id="home"
    >
      <div className="home-center">
        <div className="home-desc-center">
          <div className="container">
            <div className="row position-relative align-items-center pt-4">
              <div className="col-lg-7 offset-lg-5">
                <div className="title-heading studio-home bg-white shadow mt-5  hero-title-background">


                  <h1 className="heading mb-3">
                    <img style={{ width: '11rem' }} alt='Carrara Logo' src={CarraraLogoType}></img> <span style={{ fontSize: '35px' }} className="text-primary"> Pilates </span>
                  </h1>
                  <p className="para-desc">
                    Carrara Pilates ile sağlıklı bir başlangıç yapın. 
              </p>
              <p className="para-desc">
                 Hemen <b>ücretsiz</b> deneme dersi için bize ulaşın.
              </p>
                  {/* <p style={{ display: 'contents' }} >
                    <FaClock /><span className="text-muted"> Hafta içi </span>08:00-20:00 <br />
                    <FaClock /><span className="text-muted"> Hafta sonu </span>08:00-20:00
              </p> */}

                  <div className="mt-4">
                    {/* <div className="alert alert-light alert-pills" role="alert">
                      <span className="badge badge-pill badge-info"> Uyarı </span>
                      <span className="alert-content"> Salgın dolayısıyla saatlerimiz değişkenlik gösterebilir.</span>
                    </div> */}
                    <Link
                      to="#admission"
                      className="btn btn-primary mt-2 mr-2"
                    >
                      Hemen Kaydol
                </Link>
                    <Link
                      to="/dersler"
                      className="btn btn-outline-primary mt-2"
                    >
                      Tüm Dersler
                  </Link>

                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
