import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "dersler" } }, limit: 6) {
        edges {
          node {
            childMarkdownRemark {
              fields {
                slug
              }
              frontmatter {
                title
                description
                BlogImage 
                }
              }
            }
          }
        }
      }
  `)
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h4 className="title mb-4">Popüler dersler</h4>
            <p className="text-muted para-desc mx-auto mb-0">
              <span className="text-primary font-weight-bold">
                Carrara Pilates
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        {data.allFile.edges.map(({ node }) => (
          <div className="col-lg-4 col-md-6 col-12  pb-2 pt-2">
            <Link
              to={node.childMarkdownRemark.fields.slug}
            >
              <div className="courses-desc rounded border position-relative overflow-hidden">
                <div className="position-relative d-block overflow-hidden">
                   {node.childMarkdownRemark.frontmatter.BlogImage ? (
                    <img
                    className="img-fluid rounded-top mx-auto courses-box"
                    src={node.childMarkdownRemark.frontmatter.BlogImage}
                    alt="Gatsby Docs are awesome"
                    />
                  ) : null} 
                  <div className="overlay-work" />
                </div>
                <div className="content p-3">
                  <h5>
                    <Link
                      to={node.childMarkdownRemark.fields.slug}
                      className="title text-dark"
                    >
                      {node.childMarkdownRemark.frontmatter.title}
                    </Link>
                  </h5>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className="col-12 mt-4 mb-4 pt-2 text-center">
        <Link to="/dersler" className="btn btn-primary">
          Tüm dersler <i className="mdi mdi-chevron-right" />
        </Link>
      </div>
    </div>
  )
}
