import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
  query StudioGalleryLanding {
    allCloudinaryMedia(limit: 8, filter: {public_id: {regex: "/landing-summary/"}}) {
      edges {
        node {
          id
          public_id
          format
          resource_type
          version
          secure_url
        }
      }
    }
  }
    
    `)
  return (
    <div className="container mt-100 mt-60" id="instructors">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h4 className="title mb-4">Stüdyo</h4>
            <p className="text-muted para-desc mx-auto mb-0">
              <span className="text-secondary font-weight-bold">
                Carrara Pilates
              </span>
              'de konusunda uzman eğitmenlerle birlikte çalışın.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        {data.allCloudinaryMedia.edges.map(({ node }) => (
          <div className="col-lg-3 col-md-6 mt-4 pt-2">
            <div className="team text-center">
              <div className="position-relative">
                <img
                  loading='lazy'
                  className="img-fluid d-block rounded gallery-box"
                  alt={`Carrara Pilates ${node.public_id}`}
                  srcSet={`https://res.cloudinary.com/carrara-pilates/image/upload/c_scale,q_50,w_1080/v${node.version}/${node.public_id}.webp`}
                  src={`https://res.cloudinary.com/carrara-pilates/image/upload/c_scale,q_50,w_1080/v${node.version}/${node.public_id}.webp`}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="col-12 mt-4 mb-4 pt-2 text-center">
        <Link to="/studyo" className="btn btn-primary">
          Stüdyoyu İncele<i className="mdi mdi-chevron-right" />
        </Link>
      </div>
    </div>
  )
}
