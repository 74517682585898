import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Features from "../components/features"
import Admission from "../components/admission"
import Courses from "../components/courses"
import GalleryLP from "../components/gallery"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO 
    title="Ana Sayfa"
    />
    <Hero />
    <Features />
    <Courses />
    <GalleryLP />
    <Admission />
  </Layout>
)

export default IndexPage
