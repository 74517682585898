import React from "react"
import FeaturesData from "../data/features.yaml"
import CafeLogo from "../images/features/cafe.svg"
import CarraraLogoType from "../images/carrara_logo/carrara-logotype.svg"

export default () => {
	const data = FeaturesData

	return (
		<section className="section">
			<div className="container" style={{ padding: '250px 0 0' }}>
				<div className="row">
					{data.features.map((data) => {
						return (
							<div className="col-md-3">
								<div className="course-feature text-centerposition-relative overflow-hidden d-block bg-white rounded p-4 pt-5 pb-5">
								<div className="position-relative text-center">
								   <img className="img-fluid rounded-top mx-auto feature-box" src={data.image} alt={data.title} />
									</div>
									<h4 className="mt-3">
										<p className="title text-dark">
											{data.title === "C'Cafe" ?
												<div>
													<img src={CarraraLogoType} style={{ width: '5rem', marginRight:'5px' }} alt={data.title} />
													<img src={CafeLogo} style={{ width: '5rem' }} alt={data.title} />
												</div>
												:
												data.title
											}
										</p>
									</h4>
									<p className="text-muted text-left">
										{data.description}
									</p>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</section>

	)
}